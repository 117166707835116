import { Map } from "immutable";
import { STATE_STATUS } from "../constants";
import { SUPPLIER_ACTIONS } from "../constants/supplier";

const initialState = new Map({
  status: STATE_STATUS.UNFETCHED,
  data: new Map({}),
  error: "",
});

const supplierReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SUPPLIER_ACTIONS.FETCH_SUPPLIER:
      return state
        .set("status", STATE_STATUS.FETCHING)
        .set("error", "")
        .set("data", new Map({}));
    case SUPPLIER_ACTIONS.FETCHED_SUPPLIER:
      return state
        .set("status", STATE_STATUS.FETCHED)
        .set("error", "")
        .set("data", new Map(payload.data));
    case SUPPLIER_ACTIONS.FAILED_FETCH_SUPPLIER:
      return state
        .set("status", STATE_STATUS.FAILED_FETCH)
        .set("data", new Map({}))
        .set("error", payload.error);
    default:
      return state;
  }
};

export default supplierReducer;
