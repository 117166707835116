import { Map } from "immutable";
import { STATE_STATUS } from "../constants";
import { AUTH_STATUS } from "../constants/auth";

const initialState = new Map({
  status: STATE_STATUS.UNFETCHED,
  data: new Map({}),
});

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_STATUS.SET_AUTH:
      return state
        .set("status", STATE_STATUS.FETCHED)
        .set("data", action.payload.auth);
    default:
      return state;
  }
};

export default authReducer;
