import { call, fork, put, takeLatest } from "redux-saga/effects";
import { SUPPLIER_ACTIONS } from "../constants/supplier";
import { getSupplier } from "../../services/supplier";
import { failedFetchSupplier, fetchedSupplier } from "../actions/supplier";
function* fetchSupplierData({ payload }) {
  try {
    const { data } = yield call(getSupplier);
    if (data.success) {
      yield put(fetchedSupplier(data?.data));
    } else {
      yield put(failedFetchSupplier(data?.message));
    }
  } catch (e) {
    console.log(e?.response?.data);
    yield put(failedFetchSupplier("error"));
  }
}

export default fork(function* () {
  yield takeLatest(SUPPLIER_ACTIONS.FETCH_SUPPLIER, fetchSupplierData);
});
