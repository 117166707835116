import React, { useEffect, useState } from "react";
import "./index.css";
// import { GoogleLogin } from "@react-oauth/google";
import { login, signup } from "../../services/auth";
// import {
//   getDownloadURL,
//   ref as storageRef,
//   uploadBytes,
// } from "firebase/storage";

const Auth = (props) => {
  const isSupplier = window.location.href.includes("isSupplier=true");
  const isInstaller = window.location.href.includes("isInstaller=true");
  const paramsData = window.location.href
    ?.split("?")?.[1]
    ?.split("&")
    ?.map((_) => ({ key: _?.split("=")?.[0], value: _?.split("=")?.[1] }));
  const source = paramsData?.find((_) => _?.key == "utm_source")?.value;
  const medium = paramsData?.find((_) => _?.key == "utm_medium")?.value;
  const campaign = paramsData?.find((_) => _?.key == "utm_campaign")?.value;
  const channel = paramsData?.find((_) => _?.key == "utm_channel")?.value;

  const { setIsAuthenticated, setToken, setAuthData } = props;

  const FLOWS = {
    0: {
      title: `Login`,
      index: 0,
      updateState: 1,
      hyperLinkText: "Don't have an account? Sign Up",
    },
    1: {
      title: `Sign Up`,
      index: 1,
      updateState: 0,
      hyperLinkText: "Already have an account? Login",
    },
  };
  const [flow, setFlow] = useState(0);
  // const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageUpload, setImageUpload] = useState(null);

  const onNext = async () => {
    try {
      // const imageRef = storageRef(storage, `resixty/suppliers/1234/images`);

      // uploadBytes(imageRef, imageUpload)
      //   .then((snapshot) => {
      //     getDownloadURL(snapshot.ref)
      //       .then((url) => {
      //         console.log(url, "url");
      //       })
      //       .catch((error) => {
      //         console.log(error.message, "error 1");
      //       });
      //   })
      //   .catch((error) => {
      //     console.log(error.message, "error");
      //   });

      let authObj = flow
        ? {
            user_type: isInstaller ? 2 : isSupplier ? 1 : 3,
            channel: `${source} | ${medium} | ${campaign} | ${channel}`,
            email,
            phone: number,
            password,
          }
        : {
            email,
            phone: number,
            password,
          };
      setLoading(true);
      const { data } = flow ? await signup(authObj) : await login(authObj);
      if (data?.success) {
        setAuthData(data?.data);
        setToken(data?.data?.token);
        setIsAuthenticated(true);
      } else {
        alert(data?.message);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="card">
        <div className="fieldsContainer">
          <div className="df fdc alic">
            <img
              src={require("../../assets/logo.png")}
              alt="logo"
              className="formLogo"
            />
            <h6 className="mt-10 colorGrexTextColor boldFont">
              {isSupplier || isInstaller ? "Partner" : ""}{" "}
              {FLOWS?.[flow]?.title}
            </h6>
            <h6
              className="cp tdul colorGrexTextColor mediumFont"
              onClick={() => setFlow(FLOWS?.[flow]?.updateState)}
            >
              {FLOWS?.[flow]?.hyperLinkText}
            </h6>
          </div>
          <div className="df fdc mt-10">
            <div className="fieldItem">
              {flow ? (
                <>
                  <label className="colorGrexTextColor " htmlFor="number">
                    Mobile Number
                  </label>
                  <input
                    className="mt-5"
                    value={number}
                    onChange={(e) => setNumber(e?.target?.value)}
                    type="tel"
                    id="number"
                    name="number"
                    placeholder="9988****55"
                  />
                </>
              ) : null}
            </div>
            <div className="fieldItem">
              <label className="mt-10 colorGrexTextColor " htmlFor="email">
                Email
              </label>
              <input
                className="mt-5"
                value={email}
                onChange={(e) => setEmail(e?.target?.value)}
                type="email"
                id="email"
                name="email"
                placeholder="JohnDoe@mail.com"
              />
            </div>
            <div className="fieldItem">
              <label className="mt-10 colorGrexTextColor " htmlFor="password">
                Password
              </label>
              <input
                className="mt-5"
                value={password}
                onChange={(e) => setPassword(e?.target?.value)}
                type="password"
                id="password"
                name="password"
                placeholder="*********"
              />
            </div>
            <button
              className="customCta authBtn"
              disabled={loading}
              onClick={onNext}
            >
              <span className="mediumFont colorDark">
                {FLOWS?.[flow]?.title}
              </span>
              {loading ? (
                <i
                  className="fa fa-spinner fa-spin colorWhite ml-10"
                  style={{ fontSize: "16px" }}
                ></i>
              ) : null}
            </button>
          </div>
          {/* {isSupplier || isInstaller ? null : (
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                console.log(credentialResponse);
              }}
              ux_mode={"popup"}
              scope={
                "email profile openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email"
              }
              shape={"rectangular"}
              theme={"filled_black"}
              logo_alignment={"left"}
              login_uri={"http://localhost:3000"}
              onError={() => {
                console.log("Login Failed");
              }}
              useOneTap
            />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Auth;
