export const CONFIG = {
  BASE_URL: "http://apidev.resixty.com/api/",
  // BASE_URL: "http://127.0.0.1:8001/api/",
  GOOGLE_BASE_URL: "https://maps.googleapis.com/maps",
  GEO_ENCODING: "/api/geocode/json",
  REVERSE_GEO_ENCODING: "/api/geocode/json",
  MAPS_KEY: "AIzaSyDPE7jHLruLCAV3iu0yt4FIaSQWuXo2w-o",
  PVWATTS_BASE_URL: "https://developer.nrel.gov/api/pvwatts/v6.json",
  PVWATTS_API_KEY: "Ay2xaPeusXZX9l9tRIvIHnZSjW4RZ6RGP7lJIsMn",
  // Configurations for the map
  defaultLatitude: 26.2131187,
  defaultLongitude: 78.1772273,

  // Default metrics configuration
  defaultWattagePerSqM: 222,
  defaultUtilityRate: 0.14,

  // Configurations for the solar panel
  solarPanelName: "SunPower X21-335-BLK",
  solarPanelPower: 0.55,
  solarPanelArea: 3.1, // in SqM
  solarPanelUnitPrice: 17000,
  albedo: 0,
  soiling: 2,
  inv_eff: 98,
  losses: 12.75,
  gcr: 0.8, //rcc 0.4-0.45
};
