import { SUPPLIER_ACTIONS } from "../constants/supplier";

export const fetchSupplier = () => ({
  type: SUPPLIER_ACTIONS.FETCH_SUPPLIER,
});

export const fetchedSupplier = (data) => ({
  type: SUPPLIER_ACTIONS.FETCHED_SUPPLIER,
  payload: {
    data,
  },
});

export const failedFetchSupplier = (error) => ({
  type: SUPPLIER_ACTIONS.FAILED_FETCH_SUPPLIER,
  payload: {
    error,
  },
});
