import React from "react";
import "./index.css";

const Header = (props) => {
  const { isAuthenticated, setIsAuthenticated, setToken, setAuthData } = props;
  const isSupplier = window.location.href.includes("isSupplier=true");
  const isInstaller = window.location.href.includes("isInstaller=true");
  const handleCta = () => {
    if (isAuthenticated) {
      setIsAuthenticated(false);
      setToken(null);
      setAuthData(false);
    } else {
      if (isSupplier || isInstaller) {
        window.location.href = "/";
      } else {
        window.location.href = "?isSupplier=true";
      }
    }
    openCloseMenu();
  };

  const openCloseMenu = () => {
    let menuElement = document.getElementById("menu");
    if (
      menuElement.classList.contains("slide-out") ||
      !menuElement.classList.contains("slide-in")
    ) {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("overflow-hidden");
      menuElement.classList.remove("slide-out");
      menuElement.classList.add("slide-in");
      setTimeout(() => {
        menuElement.classList.add("d-none");
        menuElement.classList.remove("df");
      }, 10);
    } else {
      document.getElementsByTagName("body")[0].classList.add("overflow-hidden");
      menuElement.classList.remove("slide-in");
      menuElement.classList.add("slide-out");
      menuElement.classList.remove("d-none");
      menuElement.classList.add("df");
      menuElement.classList.add("fdc");
      menuElement.classList.add("alic");
    }
  };

  return (
    <header>
      <div className="topStrip w-100 df fdrr">
        <p className="colorWhite">Contact Number: +91 8700977671</p>
      </div>
      <div className="headerContainer">
        <a href="#">
          <img
            src={require("../../assets/logo.png")}
            alt="logo"
            className="headerImage"
          />
        </a>
        <div className="mobile-menu">
          <span
            onClick={() => openCloseMenu()}
            style={{
              margin: 0,
              color: "var(--primaryGreen)",
              fontSize: "2rem",
            }}
          >
            &#9776;
          </span>
        </div>
        <div
          className="menu-container overflow-hidden d-none slide-in"
          id="menu"
        >
          <span
            onClick={() => openCloseMenu()}
            className="alsfs"
            style={{
              margin: "1rem",
              color: "var(--primaryGreen)",
              fontSize: "2rem !important",
            }}
          >
            &#x2715;
          </span>
          <div
            className="menu-item cursor-pointer"
            onClick={() => openCloseMenu()}
          >
            <a href="about.html">
              <span className="mediumFont">About us</span>
            </a>
          </div>

          <div
            className="menu-item cursor-pointer"
            onClick={() => openCloseMenu()}
          >
            <a href="ecotag.html">
              <span className="mediumFont">Ecotag</span>
            </a>
          </div>
          <div
            className="menu-item cursor-pointer"
            onClick={() => openCloseMenu()}
          >
            <a href="assurance.html">
              <span className="mediumFont">Assurance</span>
            </a>
          </div>
          <div
            className="menu-item cursor-pointer"
            onClick={() => openCloseMenu()}
          >
            <a href="index.html#contact">
              <span className="mediumFont">Contact</span>
            </a>
          </div>
          <button className="w-80 asc headerCta" onClick={() => handleCta()}>
            {isAuthenticated
              ? "Logout"
              : isSupplier || isInstaller
              ? "Customer Login"
              : "Partner login"}
          </button>
        </div>
        <div className="df fdr alic headerLinks">
          <a href="about.html">
            <span className="ml-20 mediumFont">About us</span>
          </a>
          <a href="ecotag.html">
            <span className="ml-20 mediumFont">Ecotag</span>
          </a>
          <a href="assurance.html">
            <span className="ml-20 mediumFont">Assurance</span>
          </a>
          <a href="index.html#contact">
            <span className="ml-20 mediumFont">Contact</span>
          </a>
          <button className="ml-20 headerCta" onClick={() => handleCta()}>
            {isAuthenticated
              ? "Logout"
              : isSupplier || isInstaller
              ? "Customer Login"
              : "Partner login"}
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
