import { applyMiddleware, createStore } from "redux";
import rootReducer from "./reducers";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";
import { logger } from "./middleware/logger";

const sagaMiddleware = createSagaMiddleware();

const middlewares = [logger];
const enhancers = [];
enhancers.push(applyMiddleware(...middlewares));

const store = createStore(
  rootReducer,
  {},
  applyMiddleware(sagaMiddleware, logger)
);

sagaMiddleware.run(rootSaga);

export default store;
