import axios from "axios";
import { CONFIG } from "../generic/config";
import { getToken } from "../generic/func";

export const getUserDetails = async () =>
  axios.get(`${CONFIG.BASE_URL}user/getUserDetails`, {
    headers: {
      "x-access-token": getToken(),
    },
  });

export const saveUserDetails = async (details) =>
  axios.post(`${CONFIG.BASE_URL}user/setUserDetails`, details, {
    headers: {
      "x-access-token": getToken(),
    },
  });

export const getUserFinancialData = async (details) =>
  axios.post(
    `${CONFIG.BASE_URL}financial/getWorkingHoursConsumption`,
    details,
    {
      headers: {
        "x-access-token": getToken(),
      },
    }
  );
