import { Map, List } from "immutable";
import { STATE_STATUS } from "../constants";
import { USERS_ACTIONS } from "../constants/user";
import { PAYMENT_MODES } from "../../constants";

const initialState = new Map({
  userDetails: new Map({
    status: STATE_STATUS.UNFETCHED,
    data: new List([]),
    error: "",
  }),
  userFinancials: new Map({
    [PAYMENT_MODES.LOAN]: new Map({
      status: STATE_STATUS.UNFETCHED,
      data: new Map({}),
      error: "",
    }),
    [PAYMENT_MODES.UPFRONT]: new Map({
      status: STATE_STATUS.UNFETCHED,
      data: new Map({}),
      error: "",
    }),
    [PAYMENT_MODES.RESCO]: new Map({
      status: STATE_STATUS.UNFETCHED,
      data: new Map({}),
      error: "",
    }),
  }),
});

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case USERS_ACTIONS.FETCH_USER_DETAILS:
      return state
        .setIn(["userDetails", "status"], STATE_STATUS.FETCHING)
        .setIn(["userDetails", "error"], "")
        .setIn(["userDetails", "data"], new List([]));
    case USERS_ACTIONS.FETCHED_USER_DETAILS:
      return state
        .setIn(["userDetails", "status"], STATE_STATUS.FETCHED)
        .setIn(["userDetails", "error"], "")
        .setIn(["userDetails", "data"], new List(payload.data));
    case USERS_ACTIONS.FAILED_FETCH_USER_DETAILS:
      return state
        .setIn(["userDetails", "status"], STATE_STATUS.FAILED_FETCH)
        .setIn(["userDetails", "data"], new List([]))
        .setIn(["userDetails", "error"], payload.error);

    case USERS_ACTIONS.FETCH_USER_FINANCIALS:
      return state
        .setIn(
          ["userFinancials", payload.loanType, "status"],
          STATE_STATUS.FETCHING
        )
        .setIn(["userFinancials", payload.loanType, "error"], "");
    case USERS_ACTIONS.FETCHED_USER_FINANCIALS:
      return state
        .setIn(
          ["userFinancials", payload.loanType, "status"],
          STATE_STATUS.FETCHED
        )
        .setIn(
          ["userFinancials", payload.loanType, "data"],
          new Map(payload.data)
        )
        .setIn(["userFinancials", payload.loanType, "error"], "");
    case USERS_ACTIONS.FAILED_FETCH_USER_FINANCIALS:
      return state
        .setIn(
          ["userFinancials", payload.loanType, "status"],
          STATE_STATUS.FAILED_FETCH
        )
        .setIn(["userFinancials", payload.loanType, "data"], new Map({}))
        .setIn(["userFinancials", payload.loanType, "error"], payload.error);
    default:
      return state;
  }
};

export default userReducer;
