import { combineReducers } from "redux";
import authReducer from "./auth";
import supplierReducer from "./supplier";
import userReducer from "./user";

const rootReducer = combineReducers({
  auth: authReducer,
  supplier: supplierReducer,
  user: userReducer,
});

export default rootReducer;
