import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { useLocation, useNavigate } from "react-router-dom";
import { CONFIG } from "../../generic/config";
import { logEvent, getAnalytics } from "firebase/analytics";
import { useDispatch, useSelector } from "react-redux";
import { STATE_STATUS } from "../../redux/constants";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Label,
} from "recharts";

import {
  fetchUserDetails,
  fetchUserFinancials,
} from "../../redux/actions/user";
import { OrderedMap } from "immutable";
import { PAYMENT_MODES } from "../../constants";

const Output = () => {
  const [carousel, setCarousel] = useState({
    wl1: true,
    wl2: true,
    wor1: true,
    wor2: true,
  });
  const userDetailsStatus = useSelector((state) =>
    state?.user.getIn(["userDetails", "status"])
  );
  const userDetailsData = useSelector((state) =>
    state?.user.getIn(["userDetails", "data"])
  );

  const userFinancialsWithLoanStatus = useSelector((state) =>
    state?.user.getIn(["userFinancials", PAYMENT_MODES.LOAN, "status"])
  );
  const userFinancialsWithoutLoanStatus = useSelector((state) =>
    state?.user.getIn(["userFinancials", PAYMENT_MODES.UPFRONT, "status"])
  );
  const userFinancialsRescoStatus = useSelector((state) =>
    state?.user.getIn(["userFinancials", PAYMENT_MODES.RESCO, "status"])
  );
  const userFinancialsWithLoanData = useSelector((state) =>
    state?.user.getIn(["userFinancials", PAYMENT_MODES.LOAN, "data"])
  );
  const userFinancialsWithoutLoanData = useSelector((state) =>
    state?.user.getIn(["userFinancials", PAYMENT_MODES.UPFRONT, "data"])
  );
  const userFinancialsRescoData = useSelector((state) =>
    state?.user.getIn(["userFinancials", PAYMENT_MODES.RESCO, "data"])
  );

  const dispatch = useDispatch();

  const analytics = getAnalytics();
  const location = useLocation();
  const navigate = useNavigate();

  const state = location.state;
  const { area, bill, data } = state;

  const [initLoader, setInitLoader] = useState(true);
  const [moduleType, setModuleType] = useState(1);

  useEffect(() => {
    logEvent(analytics, "screen_view", {
      name: "GenerationResult1",
    });
    if (userDetailsStatus == STATE_STATUS.FETCHED) {
      setInitLoader(false);
    } else {
      dispatch(fetchUserDetails());
    }
  }, []);

  useEffect(() => {
    if (userDetailsStatus == STATE_STATUS.FETCHED) {
      if (!userDetailsData?.size) {
        navigate("/onboarding", {
          state: {},
        });
      } else {
        // onGetFinancials();
      }
    }
  }, [userDetailsStatus]);

  useEffect(() => {
    if (userDetailsStatus == STATE_STATUS.FETCHED) {
      if (userDetailsData?.size) {
        onGetFinancials();
      }
    }
  }, [moduleType, userDetailsStatus]);

  useEffect(() => {
    if (
      userFinancialsWithLoanStatus == STATE_STATUS.FETCHED &&
      userFinancialsWithoutLoanStatus == STATE_STATUS.FETCHED &&
      userFinancialsRescoStatus == STATE_STATUS.FETCHED
    ) {
      setInitLoader(false);
    }
  }, [
    userFinancialsWithLoanStatus,
    userFinancialsWithoutLoanStatus,
    userFinancialsRescoStatus,
  ]);

  const onGetFinancials = () => {
    if (
      ![STATE_STATUS.FETCHING].includes(userFinancialsWithLoanStatus) ||
      ![STATE_STATUS.FETCHING].includes(userFinancialsWithoutLoanStatus) ||
      ![STATE_STATUS.FETCHING].includes(userFinancialsRescoStatus)
    ) {
      let generatedData = {
        startTime: userDetailsData.getIn(["0", "from_time"]),
        endTime: userDetailsData.getIn(["0", "to_time"]),
        sanctionedLoad: userDetailsData.getIn(["0", "sanctioned_load"]),
        connectedLoad: Number(userDetailsData.getIn(["0", "system_capacity"])),
        workingOffInYear: 52,
        holidaysInYear: 10,
        loanAvailed: true,
        tenure: 5,
        moduleType: moduleType,
        averageElectricityBill: userDetailsData.getIn([
          "0",
          "avg_monthly_bill",
        ]),
        perUnitCost: Number(userDetailsData.getIn(["0", "per_unit_cost"]) || 0),
        hourlyAC: userDetailsData.getIn(["0", "generation_data", "ac"]),
      };
      dispatch(
        fetchUserFinancials(
          { ...generatedData, modeType: PAYMENT_MODES.LOAN },
          PAYMENT_MODES.LOAN
        )
      );
      // dispatch(fetchUserFinancials(generatedData, "withLoan"));
      dispatch(
        fetchUserFinancials(
          { ...generatedData, modeType: PAYMENT_MODES.UPFRONT },
          PAYMENT_MODES.UPFRONT
        )
      );
      dispatch(
        fetchUserFinancials(
          { ...generatedData, modeType: PAYMENT_MODES.RESCO },
          PAYMENT_MODES.RESCO
        )
      );
    }
  };

  const getCost = () => {
    return Number(
      Number(CONFIG.solarPanelUnitPrice) * Number(data?.inputs?.panels)
    );
  };

  const getPerWattCost = () => {
    const cost = getCost();
    const hoursInYear = 6 * 365; //Assuming a typical solar panel operates at peak efficiency for about 6 hours per day on average.
    const totalWattageCap =
      (Number(data?.outputs?.ac_annual)?.toFixed(0) / hoursInYear) * 10000;
    return Number(cost / totalWattageCap)?.toFixed(0);
  };

  const currencyFormatter = (val) => {
    let FormattedCurrency = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      useGrouping: true,
      maximumFractionDigits: 0,
    });
    return FormattedCurrency.format(Number(val)?.toFixed(0) * 100000);
  };

  const chartWithLoandata = new OrderedMap(
    userFinancialsWithLoanData.get("cashflow")
  )
    .toList()
    .map((_, k) => ({
      name: new Date().getFullYear() + k,
      "Saving From Solar": _.cumulativeSavingFromSolar.toFixed(2),
    }))
    .toArray();

  const chartWithoutLoandata = new OrderedMap(
    userFinancialsWithoutLoanData.get("cashflow")
  )
    .toList()
    .map((_, k) => ({
      name: new Date().getFullYear() + k,
      "Saving From Solar": _.cumulativeSavingFromSolar.toFixed(2),
    }))
    .toArray();

  const chartRescodata = new OrderedMap(userFinancialsRescoData.get("cashflow"))
    .toList()
    .map((_, k) => ({
      name: new Date().getFullYear() + k,
      "Saving From Solar": _.cumulativeSavingFromSolar.toFixed(2),
    }))
    .toArray();

  const renderChart = (chartData, title) => {
    const dataMax = Math.max(
      ...[...chartWithoutLoandata, ...chartWithLoandata, ...chartRescodata].map(
        (_) => _?.["Saving From Solar"]
      )
    );
    const dataMin = Math.min(
      ...[...chartWithLoandata, ...chartWithoutLoandata, ...chartRescodata].map(
        (_) => _?.["Saving From Solar"]
      )
    );
    // let off;
    // if (dataMax <= 0) {
    //   off = 0;
    // }
    // if (dataMin >= 0) {
    //   off = 1;
    // }

    // off = dataMax / (dataMax - dataMin);
    return (
      <ResponsiveContainer width={"95%"} height={220}>
        <AreaChart
          data={chartData}
          className="mt-20"
          margin={{
            top: 10,
            // right: 10,
            left: 10,
            bottom: 20,
          }}
        >
          <XAxis dataKey="name">
            <Label
              color="#82ca9d"
              className="colorGreen"
              value={title}
              offset={0}
              position="bottom"
            />
          </XAxis>
          <YAxis
            tickFormatter={(label) => label + "L"}
            domain={[dataMin > 0 ? 0 : dataMin, dataMax + 2]}
          />
          <Tooltip
            formatter={(label, key) => {
              return "₹" + label + "L";
            }}
          />
          {/* <defs>
            <linearGradient id="splitColor" x1="1" y1="0" x2="0" y2="1">
              <stop offset={off} stopColor="#82ca9d" stopOpacity={1} />
              <stop offset={off} stopColor="#c41a31" stopOpacity={1} />
            </linearGradient>
          </defs> */}
          <Area
            type="natural"
            dataKey="Saving From Solar"
            // fill="url(#splitColor)"
            stroke="#82ca9d"
            fill="#82ca9d"
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  };

  const TABLE_DATA = [
    {
      title: "Upfront payment",
      upfront: (data) => currencyFormatter(-data.getIn(["upfrontPayment"])),
      loan: (data) => currencyFormatter(-data.getIn(["upfrontPayment"])),
      noUpfront: (data) => currencyFormatter(-data.getIn(["upfrontPayment"])),
      color: "colorRed",
    },
    {
      title: "Before Solar - electricity bill (25 Yrs)",
      upfront: (data) =>
        currencyFormatter(-data.getIn(["totalBeforeSolarBill"])),
      loan: (data) => currencyFormatter(-data.getIn(["totalBeforeSolarBill"])),
      noUpfront: (data) =>
        currencyFormatter(-data.getIn(["totalBeforeSolarBill"])),
      color: "colorRed",
    },
    {
      title: "After Solar - electricity bill (25 Yrs)",
      upfront: (data) => currencyFormatter(data.getIn(["totalAfterSolarBill"])),
      loan: (data) => currencyFormatter(data.getIn(["totalAfterSolarBill"])),
      noUpfront: (data) =>
        currencyFormatter(data.getIn(["totalAfterSolarBill"])),
      color: "colorRed",
    },
    {
      title: "Additional savings in tax (25 Yrs)",
      upfront: (data) =>
        currencyFormatter(data.getIn(["totalAdditionalTaxBenefit"])),
      loan: (data) =>
        currencyFormatter(data.getIn(["totalAdditionalTaxBenefit"])),
      noUpfront: (data) =>
        currencyFormatter(data.getIn(["totalAdditionalTaxBenefit"])),
      color: "colorGreen",
    },
    {
      title: "Total savings from Solar (25 Yrs)",
      upfront: (data) => currencyFormatter(data.getIn(["totalBillSaving"])),
      loan: (data) => currencyFormatter(data.getIn(["totalBillSaving"])),
      noUpfront: (data) => currencyFormatter(data.getIn(["totalBillSaving"])),
      color: "colorGreen",
    },
    {
      title: "Loan repayment",
      upfront: (data) => currencyFormatter(-data.getIn(["totalLoanRepayment"])),
      loan: (data) => currencyFormatter(-data.getIn(["totalLoanRepayment"])),
      noUpfront: (data) =>
        currencyFormatter(-data.getIn(["totalLoanRepayment"])),
      color: "colorRed",
    },
    {
      title: "Operational expense, AMC (25 Yrs)",
      upfront: (data) =>
        currencyFormatter(-data.getIn(["totalOperationalExpenses"])),
      loan: (data) =>
        currencyFormatter(-data.getIn(["totalOperationalExpenses"])),
      noUpfront: (data) =>
        currencyFormatter(-data.getIn(["totalOperationalExpenses"])),
      color: "colorRed",
    },
    {
      title: "Total ownership expense (25 Yrs)",
      upfront: (data) =>
        currencyFormatter(Math.abs(data.getIn(["totalOwnershipCost"]))),
      loan: (data) =>
        currencyFormatter(Math.abs(data.getIn(["totalOwnershipCost"]))),
      noUpfront: (data) =>
        currencyFormatter(Math.abs(data.getIn(["totalOwnershipCost"]))),
      color: "colorRed",
    },
    {
      title: "Nett savings from Solar (25 Yrs)",
      upfront: (data) =>
        currencyFormatter(data.getIn(["totalNetSavingFromSolar"])),
      loan: (data) =>
        currencyFormatter(data.getIn(["totalNetSavingFromSolar"])),
      noUpfront: (data) =>
        currencyFormatter(data.getIn(["totalNetSavingFromSolar"])),
      color: "colorGreen",
    },
    {
      title: "ROI",
      upfront: (data) => (data.getIn(["averageRoi"]) * 100)?.toFixed(0) + "%",
      loan: (data) => (data.getIn(["averageRoi"]) * 100)?.toFixed(0) + "%",
      noUpfront: (data) => (data.getIn(["averageRoi"]) * 100)?.toFixed(0) + "%",
      color: "colorGreen",
    },
  ];

  const renderTableData = () => {
    return (
      <div className="w-100 mt-20">
        <table id="tableData">
          <tr>
            <th className="boldFont"></th>
            <th className="boldFont">Full Upfront Payment</th>
            <th className="boldFont">With Easy Loan</th>
            <th className="boldFont">No Upfront Payment</th>
          </tr>
          {TABLE_DATA.map((row, rowKey) => (
            <tr key={rowKey}>
              <td className={row?.color}>{row?.title}</td>
              <td className={row?.color}>
                {row?.upfront(userFinancialsWithoutLoanData)}
              </td>
              <td className={row?.color}>
                {row?.loan(userFinancialsWithLoanData)}
              </td>
              <td className={row?.color}>
                {row?.noUpfront(userFinancialsRescoData)}
              </td>
            </tr>
          ))}
        </table>
      </div>
      // <div className="w-100">
      //   {isWithLoad ? (
      //     <>
      //       <div className=" mt-10 df fdr alic jcsb">
      //         <h5 className="">BUY WITH EASY LOAN</h5>
      //       </div>
      //       <div className="mt-05 df fdr alic jcsb">
      //         <h5 className="">
      //           Easy loans starting {tableData.getIn(["roi"])}
      //           %* for {tableData.getIn(["tenure"])} years
      //         </h5>
      //       </div>
      //     </>
      //   ) : (
      //     <>
      //       <div className=" mt-10 df fdr alic jcsb">
      //         <h5 className="">BUY WITH FULL UPFRONT PAYMENT</h5>
      //       </div>
      //       <div className="mt-05 df fdr alic jcsb">
      //         <h5 className="">One time payment, maximum savings!</h5>
      //       </div>
      //     </>
      //   )}
      //   <div className="mt-05 df fdr alic jcsb mt-10">
      //     <div className="df fdr jcsb w-95">
      //       <h5 className="colorGreen">Upfront payment</h5>
      //       <h5 className="colorRed boldFont">
      //         {currencyFormatter(-tableData.getIn(["upfrontPayment"]))}
      //       </h5>
      //     </div>
      //   </div>
      //   {carousel?.[isWithLoad ? "wl1" : "wor1"] ? (
      //     <>
      //       <div className="mt-05 df fdr alic jcsb">
      //         <div className="df fdr jcsb w-95">
      //           <p className="colorGreen">
      //             Before Solar - electricity bill (25 Yrs)
      //           </p>
      //           <p className="colorGreen">
      //             {currencyFormatter(
      //               -tableData.getIn(["totalBeforeSolarBill"])
      //             )}
      //           </p>
      //         </div>
      //       </div>
      //       <div className="mt-05 df fdr alic jcsb">
      //         <div className="df fdr jcsb w-95">
      //           <p className="colorGreen">
      //             After Solar - electricity bill (25 Yrs)
      //           </p>
      //           <p className="colorGreen">
      //             -
      //             {currencyFormatter(-tableData.getIn(["totalAfterSolarBill"]))}
      //           </p>
      //         </div>
      //       </div>

      //       <div className="mt-05 df fdr alic jcsb">
      //         <div className="df fdr jcsb w-95">
      //           <p className="colorGreen">Additional savings in tax (25 Yrs)</p>
      //           <p className="colorGreen">
      //             +
      //             {currencyFormatter(tableData.getIn(["totalAdditionalTaxBenefit"]))}
      //           </p>
      //         </div>
      //       </div>
      //       <div className="mt-05 df fdr alic jcsb">
      //         <div className="df fdr jcsb w-95">
      //           <h5 className="colorGreen">
      //             Total savings from Solar (25 Yrs)
      //           </h5>
      //           <h5 className="colorGreen boldFont">
      //             {currencyFormatter(tableData.getIn(["totalBillSaving"]))}
      //           </h5>
      //         </div>
      //       </div>
      //       <div className="mt-05 df fdr alic jcsb mt-10">
      //         <div className="df fdr jcsb w-95">
      //           <p className="colorGreen">Upfront payment</p>
      //           <p className="colorRed">
      //             -{currencyFormatter(-tableData.getIn(["upfrontPayment"]))}
      //           </p>
      //         </div>
      //       </div>
      //       <div className="mt-05 df fdr alic jcsb">
      //         <div className="df fdr jcsb w-95">
      //           <p className="colorGreen colorGreen">Loan repayment</p>
      //           <p className="colorRed">
      //             -{currencyFormatter(-tableData.getIn(["totalLoanRepayment"]))}
      //           </p>
      //         </div>
      //       </div>
      //       <div className="mt-05 df fdr alic jcsb">
      //         <div className="df fdr jcsb w-95">
      //           <p className="colorGreen">Operational expense, AMC (25 Yrs)</p>
      //           <p className="colorRed">
      //             -
      //             {currencyFormatter(-tableData.getIn(["totalOperationalExpenses"]))}
      //           </p>
      //         </div>
      //       </div>
      //       <div className="mt-05 df fdr alic jcsb">
      //         <div className="df fdr jcsb w-95">
      //           <h5 className="colorGreen">Total ownership expense (25 Yrs)</h5>
      //           <h5 className="colorRed boldFont">
      //             -{currencyFormatter(-tableData.getIn(["totalOwnershipCost"]))}
      //           </h5>
      //         </div>
      //       </div>
      //     </>
      //   ) : null}
      //   <div className="mt-05 df fdr alic jcsb">
      //     <div className="df fdr jcsb w-95">
      //       <h5 className="colorGreen">Nett savings from Solar (25 Yrs)</h5>
      //       <h5 className="colorGreen boldFont">
      //         {currencyFormatter(tableData.getIn(["totalNetSavingFromSolar"]))}
      //       </h5>
      //     </div>
      //     <i
      //       onClick={() => {
      //         setCarousel({
      //           ...carousel,
      //           [isWithLoad ? "wl1" : "wor1"]:
      //             !carousel?.[isWithLoad ? "wl1" : "wor1"],
      //         });
      //       }}
      //       class={`upDownIcons fa fa-solid ${
      //         carousel?.[isWithLoad ? "wl1" : "wor1"]
      //           ? "fa-chevron-down"
      //           : "fa-chevron-up"
      //       } colorGreen boldFont`}
      //       style={{ fontSize: "1.4rem", cursor: "pointer" }}
      //     ></i>
      //   </div>
      //   <div className="mt-05 df fdr alic jcsb">
      //     <div className="df fdr jcsb w-95">
      //       <h5 className="colorGreen">ROI</h5>
      //       <h5 className="colorGreen boldFont">
      //         {(tableData.getIn(["averageRoi"]) * 100)?.toFixed(0)}%
      //       </h5>
      //     </div>
      //   </div>
      // </div>
    );
  };

  const renderStep3 = () => (
    <div className="df fdc w-100 mt-30" id={`dynamicId-${2}`}>
      {initLoader ? (
        <div className="df fdr alic jcc w-100 containerDiv">
          <i
            className="fa fa-spinner fa-spin mt-10 asc df"
            style={{
              fontSize: "30px",
              color: "var(--themeGreen)",
              alignSelf: "center",
            }}
          ></i>
        </div>
      ) : (
        <div>
          <h4 className="lightFont">
            Select the best suited package according to your needs
          </h4>
          <div className="df fdr alic w-100 mt-10">
            <div
              onClick={() => setModuleType(1)}
              className={`w-35 df fdc ${
                moduleType == 1 ? "selectedCard" : "unselectedCard"
              }`}
            >
              <div className="df m-10  fdr jcsb">
                <div className="df fdr">
                  <div className="radio mt-05">
                    <div
                      className={
                        moduleType == 1 ? "selectedRadio" : "unSelectedRadio"
                      }
                    ></div>
                  </div>
                  <div className="ml-10">
                    <h4 className="colorBlue boldFont">Premium</h4>
                    <h6 className="colorGreen lightFont">N-TOPCon modules</h6>
                  </div>
                </div>
                <div className="highlightedBg">
                  <span className="colorGreen">RECOMMENDED</span>
                </div>
              </div>
            </div>
            <div
              onClick={() => setModuleType(2)}
              className={`w-35 ml-20 df fdc ${
                moduleType == 2 ? "selectedCard" : "unselectedCard"
              }`}
            >
              <div className="df fdr m-10">
                <div className="radio mt-05">
                  <div
                    className={
                      moduleType == 2 ? "selectedRadio" : "unSelectedRadio"
                    }
                  ></div>
                </div>
                <div className="ml-10">
                  <h4 className="colorBlue boldFont">Standard</h4>
                  <h6 className="colorGreen lightFont">MONO PERC modules</h6>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="asc df fdc alic mt-10 jcsb w-45"> */}
          <div className="mt-20 df fdr w-45 alic jcsb">
            <h5 className="colorGreen mediumFont">
              Recommended Project Size (kW)
            </h5>
            <h5 className="colorGreen boldFont">
              {Number(userDetailsData.getIn(["0", "system_capacity"])).toFixed(
                2
              )}
            </h5>
          </div>
          <div className="mt-05 df fdr w-45 alic jcsb">
            <h5 className="colorGreen mediumFont">
              Monthly electricity bill reduced by
            </h5>
            <h5 className="colorGreen boldFont">
              {(
                (userFinancialsWithLoanData.getIn(["totalBillSaving"]) /
                  -userFinancialsWithLoanData.getIn(["totalBeforeSolarBill"])) *
                100
              )?.toFixed(0)}
              %
            </h5>
          </div>
          <div className="mt-05 df fdr w-45 alic jcsb">
            <h5 className="colorGreen mediumFont">
              CO2 emissions reduced (Tons)
            </h5>
            <h5 className="colorGreen boldFont">
              {userFinancialsWithLoanData
                .getIn(["totalCo2Mitigation"])
                ?.toFixed(0)}
            </h5>
          </div>
          {/* </div> */}
          {renderTableData()}
          <div className="df fdr jcsb">
            <div className="w-30 df fdc mt-10">
              {/* {renderTableData(userFinancialsWithoutLoanData, false)} */}
              {renderChart(chartWithoutLoandata, "Full Upfront Payment")}
            </div>
            <div className="w-30 df fdc mt-10">
              {/* {renderTableData(userFinancialsWithLoanData, true)} */}
              {renderChart(chartWithLoandata, "With Loan")}
            </div>
            <div className="w-30 df fdc mt-10">
              {/* {renderTableData(userFinancialsWithoutLoanData, false)} */}
              {renderChart(chartRescodata, "No Upfront Payment")}
            </div>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="centerAlignedDiv">
      <div className="widthAdjuster card">{renderStep3()}</div>
    </div>
  );
};

export default Output;
