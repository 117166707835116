import { call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import { USERS_ACTIONS } from "../constants/user";
import { getUserDetails, getUserFinancialData } from "../../services/users";
import {
  failedFetchUserDetails,
  failedFetchUserFinancials,
  fetchedUserDetails,
  fetchedUserFinancials,
} from "../actions/user";
function* fetchUserDetailData({ payload }) {
  try {
    const { data } = yield call(getUserDetails);
    if (data.success) {
      yield put(fetchedUserDetails(data?.data));
    } else {
      yield put(failedFetchUserDetails(data?.message));
    }
  } catch (e) {
    console.log(e?.response?.data);
    yield put(failedFetchUserDetails("error"));
  }
}

function* fetchUserFinancials({ payload: { req, loanType } }) {
  try {
    const { data } = yield call(getUserFinancialData, req);
    if (data.success) {
      console.log(data);
      yield put(fetchedUserFinancials(data?.data, loanType));
    } else {
      yield put(failedFetchUserFinancials(data?.message, loanType));
    }
  } catch (e) {
    console.log(e?.response?.data);
    yield put(failedFetchUserFinancials("error", loanType));
  }
}

export default fork(function* () {
  yield takeLatest(USERS_ACTIONS.FETCH_USER_DETAILS, fetchUserDetailData);
  yield takeEvery(USERS_ACTIONS.FETCH_USER_FINANCIALS, fetchUserFinancials);
});
