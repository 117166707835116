import React, { useEffect } from "react";
import "./index.css";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchSolarData } from "../../services/maps";
import { logEvent, getAnalytics } from "firebase/analytics";

const Onboarding = (props) => {
  const analytics = getAnalytics();
  const { name, gstin, tilt, bill, load, onChangeUserDetails } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  //Enter your details so we can generate a quote on the basis of your submission for your solar needs.

  useEffect(() => {
    logEvent(analytics, "screen_view", {
      name: "UserDetail",
    });
  }, []);

  const renderInputs = () => (
    <div id="inputContainer" className="df fdc w-50 leftDiv">
      <label className="mt-10" htmlFor="name">
        Entity Name
      </label>
      <input
        value={name}
        onChange={onChangeUserDetails}
        type="text"
        id="name"
        name="name"
        placeholder="John Doe"
      />
      <label className="mt-10" htmlFor="gstin">
        GSTIN (optional)
      </label>
      <input
        value={gstin}
        onChange={onChangeUserDetails}
        type="text"
        id="gstin"
        name="gstin"
        placeholder="09AAA******1ZZ"
      />
      <label className="mt-10" htmlFor="tilt">
        Tilt (optional)
      </label>
      <input
        value={tilt}
        onChange={onChangeUserDetails}
        type="number"
        id="tilt"
        name="tilt"
        placeholder="0°-90°"
      />
      <label className="mt-10" htmlFor="name">
        Connected Load (in kWh)
      </label>
      <input
        value={load}
        onChange={onChangeUserDetails}
        type="number"
        id="load"
        name="load"
        placeholder="20"
      />
      <label className="mt-10" htmlFor="bill">
        Electricity Bill (monthly in ₹)
      </label>
      <input
        value={bill}
        onChange={onChangeUserDetails}
        type="number"
        id="bill"
        name="bill"
        placeholder="200000"
      />
    </div>
  );

  // const getMiddle = (prop, markers) => {
  //   let values = markers.map(m => m[prop]);
  //   let min = Math.min(...values);
  //   let max = Math.max(...values);
  //   if (prop === 'lng' && (max - min > 180)) {
  //     values = values.map(val => val < max - 180 ? val + 360 : val);
  //     min = Math.min(...values);
  //     max = Math.max(...values);
  //   }
  //   let result = (min + max) / 2;
  //   if (prop === 'lng' && result > 180) {
  //     result -= 360
  //   }
  //   return result;
  // }

  // const findCenter = (markers) => {
  //   return {
  //     lat: getMiddle('lat', markers),
  //     lng: getMiddle('lng', markers)
  //   }
  // }

  // // tests:
  // console.log(findCenter([
  //   { lat: 14.692734621176195, lng: 120.9642877585083 },
  //   { lat: 14.691963317641529, lng: 120.9715473253784 },
  //   { lat: 14.702160611177580, lng: 120.9621292582138 },
  // ]));

  const onNext = async () => {
    try {
      const {
        area: {
          module_type,
          losses,
          array_type,
          azimuth,
          albedo,
          area,
          panels,
          soiling,
          inv_eff,
          system_capacity,
          latLng,
          gcr,
        },
      } = state;
      logEvent(analytics, "click", {
        cta: "Continue",
        screen_name: "UserDetail",
        name,
        gstin,
        tilt,
        bill,
        load,
        latLng: JSON.stringify(latLng),
      });
      const { data } = await fetchSolarData({
        format: "json",
        system_capacity,
        module_type,
        losses,
        tilt,
        azimuth,
        lat: latLng?.[0]?.lat,
        lon: latLng?.[0]?.lng,
        albedo,
        area,
        array_type,
        panels,
        soiling,
        inv_eff,
        gcr,
      });
      navigate("/output", {
        state: {
          ...state,
          tilt,
          gstin,
          bill,
          data,
        },
      });
    } catch (e) {
      alert("Something went wrong! Try again after some time.");
    }
  };

  const renderStep2 = () => (
    <div className="df fdr jcsb w-100 containerDiv" id={`dynamicId-${1}`}>
      {renderInputs()}
      <div></div>
      <div className="df w-45 jcc alic fdc rightDiv">
        <span className="mb-10 instructionsText">
          {
            "Enter your details so we can generate a quote on the basis of your submission for your solar needs."
          }
        </span>
        <button
          onClick={onNext}
          // disabled={}
          className="cta mt-10"
        >
          Continue
        </button>
      </div>
    </div>
  );

  return (
    <div className="centerAlignedDiv">
      <div className="widthAdjuster card">{renderStep2()}</div>
    </div>
  );
};

export default Onboarding;
