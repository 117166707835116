import axios from "axios";
import { CONFIG } from "../generic/config";
import { getToken } from "../generic/func";

export const getSupplier = async () =>
  axios.get(`${CONFIG.BASE_URL}supplier/getSupplierDetails`, {
    headers: {
      "x-access-token": getToken(),
    },
  });

export const createSupplierProfile = async (profile) =>
  axios.post(`${CONFIG.BASE_URL}supplier/setSupplierDetails`, profile, {
    headers: {
      "x-access-token": getToken(),
    },
  });
