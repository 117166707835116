import { USERS_ACTIONS } from "../constants/user";

export const fetchUserDetails = () => ({
  type: USERS_ACTIONS.FETCH_USER_DETAILS,
});

export const fetchedUserDetails = (data) => ({
  type: USERS_ACTIONS.FETCHED_USER_DETAILS,
  payload: {
    data,
  },
});

export const failedFetchUserDetails = (error) => ({
  type: USERS_ACTIONS.FAILED_FETCH_USER_DETAILS,
  payload: {
    error,
  },
});

export const fetchUserFinancials = (req, loanType) => ({
  type: USERS_ACTIONS.FETCH_USER_FINANCIALS,
  payload: {
    req,
    loanType,
  },
});

export const fetchedUserFinancials = (data, loanType) => ({
  type: USERS_ACTIONS.FETCHED_USER_FINANCIALS,
  payload: {
    data,
    loanType,
  },
});

export const failedFetchUserFinancials = (error, loanType) => ({
  type: USERS_ACTIONS.FAILED_FETCH_USER_FINANCIALS,
  payload: {
    error,
    loanType,
  },
});
